import styled from "styled-components"
import Heading from "../Heading/Heading"
import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
import loud_logo from "../../images/lp_logo.png";

const Wrapper = styled.div`
display:flex;
flex-direction:column;
@media screen and (max-width:870px){
    margin-bottom:0;
}
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`
const Paragraph = styled.p`
font-size:18px;
margin-left:50px;
@media screen and (min-width:1400px){
    padding:0 50px;
    margin-left:0;
}
`

const Heading2 = styled.p`
font-weight:700;
font-size:28px;
margin:25px 0;
margin-left:50px;
@media screen and (min-width:1400px){
    padding:0 50px;
    margin-left:0;
}
`
const Logo = styled.img`
width:60%;
margin-left:50px;
margin-bottom:50px;
@media screen and (min-width:1200px){
    width:30%;
    
}
`

const Contact = () => {
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>Kontakt</Heading>
        <Logo src={loud_logo}/>
        <Paragraph>Masz propozycję współpracy?<br/> Potrzebujesz więcej informacji?</Paragraph>
        <Paragraph><strong>NAPISZ DO NAS!</strong></Paragraph>
        <Heading2>fp@loudprod.pl</Heading2>
        <Paragraph><strong>LOUD PRODUCTION SP. Z O.O.</strong></Paragraph>
        <Paragraph>Parafialna 32/34</Paragraph>
        <Paragraph>52-233 Wrocław</Paragraph>
        <Paragraph>NIP 899 290 78 64</Paragraph>
        </Wrapper>
        <Footer/>
        </>
    )
}

export default Contact;