import styled from "styled-components";

const Wrapper = styled.a`
  width: 15vw;
  height: auto;
  text-decoration: none;
  outline: none;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 0;
  @media screen and (min-width: 1400px) {
    width: 200px;
    height: 200px;
  }
`;
const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const Exhibitor = (props) => {
  return (
    <Wrapper href={props.link ? props.link : false} target="_blank">
      <Image src={props.img} />
    </Wrapper>
  );
};

export default Exhibitor;
