import styled from "styled-components";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import Heading from "../Heading/Heading";
import NewsShort from "../NewsShort/NewsShort";

const Wrapper = styled.div`
position:relative;
`

const Description = styled.p`
margin:50px;
text-align:justify ;
`

const Masonry = styled.div`
padding:0 50px;
  column-count: 4;
  column-gap: 0.6em;
  @media (max-width: 1600px) {
    column-count: 3;
  }
  @media (max-width: 1000px) {
    column-count: 2;
  }
`;

const Image = styled.img`
  display: inline-block;
  margin:0.21em 0;
  width: 100%;
`;

const GallerySaturday = ()=>{
  window.scrollTo(0, 0);
  const Images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 76, 77, 78, 79, 80, 81, 82, 83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104]
    
  return (
      <Wrapper>
          <Navigation/>
          <Heading>Drugi dzień Festiwalu Pasibrzucha - dla każdego coś dobrego.</Heading>
          <Description>
          Drugi dzień Festiwalu Pasibrzucha otworzyliśmy atrakcjami dla najmłodszych. Na scenie głównej mogliście wspólnie obejrzeć animację SING! Od samego rana ruszyła też nasza strefa gastro, gdzie każdy mógł znaleźć coś pysznego dla siebie. Po południu rozstrzygnęliśmy Plebiscyt na najlepszego food trucka na Festiwalu Pasibrzucha, podczas którego nasi Jurorzy (Natalia Gmyrek, Anna Łempicka, Piotr Gładczak, Jakub Barański) wyłonili 3 zwycięzców w kategorii słonego jedzenia i jednego słodziaka. Tego dnia triumfowali na 1 miejscu Chingu, na drugim Solleim a na 3 Jao Zi oraz słodziak Tentego.
<br/><br/>
W ciągu całego dnia nie zabrakło magicznych wrażeń, bo mieliście okazję zobaczyć aż 3 występy iluzjonistyczne! Jako pierwszy wystąpił Traveller magician Daniel Jedynak. Następnie swoimi sztuczkami zaczarował Was Łukasz Grant. Na scenie głównej pojawił się też Macieja Pędy ze Sztuka Magii, który najpierw zachwycał Was iluzją ze sceny, a później mieliście okazję sprawdzić jego umiejętności face to face. 
Sobotni wieczór rozruszał Was wrocławski zespół Prosto z Mostów oraz latynowskie rytmy Faustina Calavera. Ale to nie był koniec wrażeń na sobotę! Na scenie głównej pojawił się Letni! Którzy zagrał takie hity jak „Pomidorowa” czy „Czinkłaczento”? Późnym wieczorem mieliście okazję trochę się pośmiać podczas darmowego kina nocnego przy kontynuacji komediowego klasyka American Pie: Zjazd Absolwentów.
          </Description>
      <Masonry>
          {
              Images.map(el=>{
                  return(
                      <Image src={`/galeria_sobota/festiwal-sobota-${el}.jpg`}/>
                  );
              })
          }
          

      </Masonry>
      <NewsShort/>
      <Footer/>
      </Wrapper>
      
  );
}

export default GallerySaturday;