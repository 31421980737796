import styled from "styled-components";
import Heading from "../Heading/Heading";
import { news2024 } from "../../data";
import News from "./News";
import GalleryFridayThumbnail from "../Gallery/GalleryFridayThumbnail";
import GallerySaturdayThumbnail from "../Gallery/GallerySaturdayThumbnail";
import GallerySundayThumbnail from "../Gallery/GallerySundayThumbnail";
import Aftermovie from "../Gallery/AftermovieThumbnail";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1400px) {
    padding: 50px 10vw;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  flex-wrap: wrap;
  @media screen and (min-width: 1400px) {
    padding: 0;
  }
`;

const NewsShort = () => {
  const last16 = news2024.slice(0, 16);
  return (
    <Wrapper>
      <Heading>Aktualności</Heading>
      <NewsWrapper>
        {last16.map((el) => (
          <News news={el} />
        ))}
        <Aftermovie />
        {/* <GallerySundayThumbnail />
        <GallerySaturdayThumbnail />
        <GalleryFridayThumbnail /> */}
      </NewsWrapper>
    </Wrapper>
  );
};

export default NewsShort;
