import { useContext } from "react"
import styled from "styled-components"
import Context from "../../context"

const Wrapper = styled.div`
position: relative;
height:25px;
width:32px;
display:flex;
align-items:center ;
cursor: pointer;
z-index:999;
`
const InActive = styled.div`
background-color:white;
width:32px;
height:3px;
border-radius:5px;

&:before,&:after{
    display:block;
    content:'';
    position:absolute;
    background-color:white;
    width:32px;
    height:3px;
    border-radius:5px;
}
&:before{
    top:0;
}
&:after{
    bottom:0;
}
`

const Active = styled.div`
background-color:rgba(255,255,255,0);
width:32px;
height:3px;
border-radius:5px;

&:before,&:after{
    display:block;
    content:'';
    position:absolute;
    background-color:white;
    width:32px;
    height:3px;
    border-radius:5px;
}
&:before{
    transform: rotate(45deg) ;
}
&:after{
    transform: rotate(-45deg) ;
}
`

const HamburgerIcon = () => {
    const { state, dispatch } = useContext(Context);

    return (
    <Wrapper  onClick={()=>dispatch("TOGGLE_MENU")}>
        {state.menuActive
        ?<Active/>
        :<InActive/>}
    </Wrapper>
    )
}

export default HamburgerIcon;