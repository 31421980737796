import styled from "styled-components";

const Video = styled.video`
  display: block;
  width: 100%;
  max-height: 56.25vw;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export default Video;