import styled from "styled-components"

const Heading = styled.p`
display:flex;
align-items:center ;
color:#131339;
font-size:25px;
font-weight:900;
text-transform:uppercase ;
margin:50px;
&:before{
    content:"";
    display:block;
    background-color:#131339 ;
    height:6px;
    width:30px;
    margin-right:8px;
}
`

export default Heading;