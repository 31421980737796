import styled from "styled-components"
import jedzenie from "../../images/icons/JEDZENIE.png"
import picie from "../../images/icons/PICIE.png"
import craft from "../../images/icons/CRAFT.png"
import omg from "../../images/icons/OMG.png"
import scena from "../../images/icons/SCENA.png"
import dzieci from "../../images/icons/DZIECI.png"
import skate from "../../images/icons/SKATE.png"
import chill from "../../images/icons/CHILL.png"
import Heading from "../Heading/Heading"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
padding:50px 0;
padding-bottom:100px;
background-color:#EEEEEE;
background-position:center ;
background-size: cover ;
@media screen and (min-width:1400px){
    padding:50px 10vw;
    padding-bottom:100px;
}
`

const Row = styled.div`
display:flex;
flex-direction:column;
@media screen and (min-width:600px){
    flex-direction:row;
    flex-wrap:wrap;
}
`

const Column = styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
flex-direction:column;
flex: 1 1 100%;
padding:25px;
@media screen and (min-width:600px){
    flex: 1 1 50%;
}
@media screen and (min-width:1000px){
    flex: 1 1 25%;
}
`

const Icon = styled.img`
width:150px;
height:150px;
margin-bottom:25px;
`

const Name = styled.p`
font-weight:900;
font-size:18px;
margin-bottom:10px;
text-transform:uppercase ;
text-align:center ;
`

const Description = styled.p`
text-align:center;
font-weight:500;
max-width:300px;
`

const InfoIcons = () => {
return (
    <Wrapper>
        <Heading>Atrakcje</Heading>
    <Row>
        <Column><Icon src={jedzenie}/><Name>ŻARCIE Z KOSMOSU</Name><Description>Najlepsza i unikalna strefa streetfoodu. Foodtrucki i smaczne koncepty gastro, bo na festiwalu nikt nie
będzie chodził głodny!</Description></Column>
        <Column><Icon src={picie}/><Name>BĘDZIE SIĘ LAŁO</Name><Description>Kraftowe piwka, aperol a może rum? Na Festiwalu Pasibrzucha dorwiesz klasyki, ale też autorskie
napoje. Z nami się nie napijesz?</Description></Column>
        <Column><Icon src={craft}/><Name>CRAFT ZONE</Name><Description>Festiwal Pasibrzucha to nie tylko jedzenie! Szykujemy dla Was handmade w najlepszym wydaniu czyli
ponad 30 rzemieślników z unikatowymi produktami.</Description></Column>
        <Column><Icon src={omg}/><Name>OMG! CO TO BĘDZIE!</Name><Description>Planszówki, karcianki czy gry imprezowe czyli strefa One More Game, to coś czego nie możecie
przegapić. Będzie to przedsmak wiekszego eventu, który szykujemy dla Was już tej jesieni.</Description></Column>
    </Row>
    <Row>
        <Column><Icon src={dzieci}/><Name>KIDS ZONE</Name><Description>Dla najmłodszych Pasibrzuchów także nie zabraknie zajawki. Zero nudy dla dzieciaków, bo
przygotowaliśmy Survival Race Kids, dmuchańce, wyścigi i konkursy z nagrodami, a co więcej to
wpadajcie sprawdzić.</Description></Column>
        <Column><Icon src={scena}/><Name>SCENA PEŁNA EMOCJI</Name><Description>Koncerty, DJe, Stand Up i pokazy to coś co Rasowe Pasibrzuchy lubią najbardziej! Czujesz ten rytm, a nóżka chodzi Ci na samą myśl o tegorocznym Festiwalu, prawda?</Description></Column>
        <Column><Icon src={skate}/><Name>SZALEŃSTWO NA MAXA</Name><Description>Na razie nie możemy zdradzić wszystkich tajemnic, ale na bank zaskoczymy Cię tak, że drugiej edycji
Festiwalu będziesz opowiadał swojej teściowej i sołtysowi.</Description></Column>
        <Column><Icon src={chill}/><Name>CHILLOUT NA 100%</Name><Description>Leżaczki, drineczki i totalny chill na trawie to must have Festiwalu Pasibrzucha. Nie musicie nigdzie wyjeżdżać, bo Partynice to idealne miejsce do błogiego nic nie robienia.</Description></Column>
    </Row>
    </Wrapper>
)

}

export default InfoIcons;