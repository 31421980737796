import styled from "styled-components";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import NewsShort from "../NewsShort/NewsShort";

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -100px;
  @media screen and (min-width: 1400px) {
    width: 70vw;
  }
`;
const Hero = styled.img`
  width: 100%;
  border: 1px solid gray;
`;
const Heading = styled.p`
  display: flex;
  align-items: center;
  color: #131339;
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 50px 0;
`;

const Description = styled.p`
  margin-bottom: 50px;
  text-align: justify;
  line-height: 175%;
`;
const Button = styled.a`
  text-decoration: none;
  color: #e94b49;
  border: 4px solid #e94b49;
  padding: 20px 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  transition: 0.3s all ease;
  margin-left: 50px;
  align-self: end;

  &:hover {
    border: 4px solid #e94b49;
    background-color: #e94b49;
    color: white;
  }
`;

const News = (props) => {
  window.scrollTo(0, 0);
  return (
    <>
      <Navigation />
      <Wrapper>
        <Hero src={props.news.photo} />
        <Heading>{props.news.title}</Heading>
        <Description
          dangerouslySetInnerHTML={{ __html: props.news.description }}
        ></Description>
        {props.news.link ? (
          <Button href={props.news.link} target="_blank">
            ZOBACZ WIĘCEJ
          </Button>
        ) : null}
      </Wrapper>
      <NewsShort />
      <Footer />
    </>
  );
};

export default News;
