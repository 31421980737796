import styled from "styled-components";
import HamburgerIcon from "./HamburgerIcon";
import Context from "../../context";
import { useContext } from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 55px;
  z-index: 999;
  top: 0;
  background-color: #131339;
  height: 100px;
  width: 100%;
  @media screen and (max-width: 640px) {
    padding: 0 35px;
  }
`;

const SiteTitle = styled.p`
  display: inline-block;
  color: white;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 24px;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 17px;
  @media screen and (max-width: 1360px) {
    display: none;
  }
`;

const MenuItem = styled.p`
  position: relative;
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-weight: 500;
  &:before {
    position: absolute;
    content: "";
    display: block;
    background-color: #e94b49;
    width: 0;
    height: 2px;
    bottom: -7px;
    right: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    &:before {
      position: absolute;
      content: "";
      display: block;
      background-color: #e94b49;
      width: 100%;
      height: 2px;
      bottom: -7px;
      right: 0;
    }
  }
`;
const LinkItem = styled.a`
  position: relative;
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-weight: 500;
  &:before {
    position: absolute;
    content: "";
    display: block;
    background-color: #e94b49;
    width: 0;
    height: 2px;
    bottom: -7px;
    right: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    &:before {
      position: absolute;
      content: "";
      display: block;
      background-color: #e94b49;
      width: 100%;
      height: 2px;
      bottom: -7px;
      right: 0;
    }
  }
`;

const Separator = styled.p`
  color: white;
  font-size: 22px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 55px;
`;

const Navigation = () => {
  const { state } = useContext(Context);
  return (
    <Wrapper>
      <FlexWrapper>
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <SiteTitle>Festiwal Pasibrzucha</SiteTitle>
        </Link>
        <MenuWrapper>
          <Link to="/aktualnosci" onClick={() => window.scrollTo(0, 0)}>
            <MenuItem>AKTUALNOŚCI</MenuItem>
          </Link>
          {/* <Separator>|</Separator>
          <Link to="/harmonogram" onClick={() => window.scrollTo(0, 0)}>
            <MenuItem>HARMONOGRAM</MenuItem>
          </Link>
          <Separator>|</Separator>
          <Link to="/partnerzy" onClick={() => window.scrollTo(0, 0)}>
            <MenuItem>PARTNERZY</MenuItem>
          </Link>
          <Separator>|</Separator>
          <Link to="/jak-dojechac" onClick={() => window.scrollTo(0, 0)}>
            <MenuItem>JAK DOJECHAĆ</MenuItem>
          </Link> */}
          {/* <LinkItem
            href="https://festiwalpasibrzucha.payeye.com/"
            target="_blank"
          >
            -20% Z PAYEYE
          </LinkItem>
          <Separator>|</Separator>
          <LinkItem
            href="https://festiwalpasibrzucha.payeye.com/konkurs/"
            target="_blank"
          >
            PASITERIA PASIBUS x PAYEYE
          </LinkItem> */}
        </MenuWrapper>
      </FlexWrapper>
      <HamburgerIcon />
      {state.menuActive ? <Menu /> : null}
    </Wrapper>
  );
};

export default Navigation;
