import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*,*::before,*::after{
    box-sizing:border-box;
    padding:0;
    margin:0;
    box-sizing:border-box;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a{
    text-decoration:none ;
}

`;

export default GlobalStyle;