import styled from "styled-components"
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer"
import Heading from "../Heading/Heading"

const Wrapper = styled.div`
margin:0 auto;
padding:50px;
@media screen and (min-width:1400px){
width:70vw;
}
`

const InfoWrapper = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
`

const Info = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
flex-basis:50%;
padding:15px;

@media screen and (max-width:970px){
    flex-basis:100%;
}
`
const InfoDesktop = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
flex-basis:50%;
padding:15px;

@media screen and (max-width:970px){
    display:none;
}
`
const InfoMobile = styled.div`
display:none;
flex-direction:column;
justify-content:center;
flex-basis:100%;
padding:15px;

@media screen and (max-width:970px){
    display:flex;
}
`

const Image = styled.img`
width:100%;
`

const Paragraph = styled.p`
font-size:20px;
`

const Heading2 = styled.p`
margin-bottom:25px;
font-size:24px;
font-weight:900;
`


const About = (props) => {
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>O FESTIWALU</Heading>
        <InfoWrapper>
        <Info><Paragraph>Kiedy klasyczna formuła zlotów foodtruckowych wyczerpuje się my postanowiliśmy pójść krok dalej i
            daliśmy Wam wyjątkowe i jedyne takie darmowe wydarzenie w Polsce.
            Z miłości do street foodu, kultury ulicznej i dobrej zabawy postał Festiwal Pasibrzucha, a Wy
            przychodząc na pierwszą edycję w blisko 40 tysięcznej frekwencji pokazaliście nam, że właśnie na takie
            wydarzenie czekaliście!</Paragraph></Info>
        <Info><Image src="https://pasibus.blob.core.windows.net/media/3528/ofest1.jpg"/></Info>
        <InfoDesktop><Image src="https://pasibus.blob.core.windows.net/media/3530/ofest3.jpg"/></InfoDesktop>
        <Info><Heading2>DLA KAŻDEGO COŚ DOBREGO</Heading2>
        <Paragraph>Wychodząc naprzeciw Waszym oczekiwaniom po raz drugi tworzymy dla was jedyny w swoim rodzaju
            Festiwal Pasibrzucha!
            Jedzenie uwielbiane przez lokalesów, dobra muza, sport, chill out, gry i handmade! Festiwal kierujemy do
            wszystkich Wrocławian, ludzi spragnioych zabawy i atrakcji! Dzieciaki też nudzić się nie będą.
            Wstęp na Festiwal Pasibrzucha jest darmowy!</Paragraph></Info>
<InfoMobile><Image src="https://pasibus.blob.core.windows.net/media/3530/ofest3.jpg"/></InfoMobile>
        <Info><Heading2>JEDNO WYDARZENIE – WIELE STREF</Heading2>

        <Paragraph>Strefa Gastro jest mocnym trzonem tworzącym Festiwal ale nie jedynym! To wydarzenie, które łączy ze
            sobą uliczny street food ze zróżnicowanymi strefami takimi jak strefa craftu, strefa gier planszowych,
            strefa moto, kids zone i wiele innych. Każdy, niezależnie od wieku, znajdzie coś dla siebie.
            Chcesz dołączyć do którejś z nich? Napisz do nas!</Paragraph></Info>
        <Info><Image src="https://pasibus.blob.core.windows.net/media/3529/ofest2.jpg"/></Info>
        <InfoDesktop><Image src="https://pasibus.blob.core.windows.net/media/3531/ofest4.jpg"/></InfoDesktop>
        <Info><Heading2>3 DNI PEŁNE EMOCJI</Heading2>
        <Paragraph>Festiwal Pasibrzucha to 3 dni, które dostarczają masę wrażeń idealnie rozpoczynają okres wakacji.
            Każdy z dni ma swój temat przewodni, tak aby każdy z Was znalazł coś dla siebie! Festiwal będzie
            prowadzony przez HOSTA, który bezbłędnie poprowadzi Was przez każdy kolejny dzień festiwalowego
            weekendu.
            Przygotowujemy dla was harmonogram uszyty na miarę i z pełną dbałością o detale jak na Pasibrzuchów
            przystało. Dobry plan to podstawa!</Paragraph></Info>
<InfoMobile><Image src="https://pasibus.blob.core.windows.net/media/3531/ofest4.jpg"/></InfoMobile>
        </InfoWrapper>
        </Wrapper>
        <Footer/>
        </>
    )
}

export default About;