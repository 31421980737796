import styled from "styled-components"
import Heading from "../Heading/Heading"
import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
import Countdown from "../Countdown/Countdown"
import photo from "../../images/partynice.jpeg"
import pajeczyna from "../../images/pajeczyna.png"
import map from "../../images/mapa.png"
import pkp from "../../images/train.png"

import bus from "../../images/bus.png"
import auto from "../../images/auto.png"
import deska from "../../images/deska.png"
import rower from "../../images/rower.png"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
margin-bottom:100px;
@media screen and (max-width:870px){
    margin-bottom:50px;
}
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const Header = styled.p`
display:flex;
align-items:center ;
color:#131339;
font-size:28px;
font-weight:900;
text-transform:uppercase ;
`

const Image = styled.img`
padding:0 50px;
`

const Map = styled.div`
width:100%;
height:500px;
background-size:100%;
background-position:center;
margin-bottom:-100px;
background-image:url(${map});
@media screen and (max-width:870px){
    background-size:300%;
}
`

const ImageSmall = styled.img`
width:60%;
margin:50px auto;
margin-bottom:10px;
@media screen and (min-width:1000px){
    width:30%;
    margin:50px 50px;
}

`
const IconWrapper = styled.div`
display:flex;
flex-direction:row;
gap:20px;
align-items:center;
justify-content:flex-start;
padding-left:50px;
margin-bottom:20px;
margin-top:20px;
`

const Icon = styled.img`
height:50px;
`

const Paragraph = styled.p`
padding:0 50px;
text-align:justify;
font-size:18px;
`

const Paragraph2 = styled.p`
padding:0 50px;
font-size:22px;
font-weight:500;
`

const Directions = () => {
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>Jak Dojechać?</Heading>
    <Image src={photo}/>
    <Heading>Partynice</Heading>
    <Paragraph>Wrocławski Tor Wyścigów Konnych Partynice. Świetnie zlokalizowany, z dobrym dojazdem i dużym parkingiem, pomieści bardzo wielu gości. Miejsce doskonale znane Wrocławianom, na świeżym powietrzu i w otoczeniu zieleni, co idealnie podkreśli niezobowiązujący klimat eventu. Rozległy teren zapewni bezpieczną zabawę.</Paragraph>
    <Heading>Adres</Heading>
    <Paragraph2>Tor Wyścigów Konnych Wrocław</Paragraph2>
    <Paragraph2>ul. Zwycięska 2</Paragraph2>
    <Heading style={{marginBottom:"10px"}}>dojazd</Heading>
    <ImageSmall src={pajeczyna}/>
    <IconWrapper><Icon src={bus}/><Header>mpk</Header></IconWrapper>
    <Paragraph2><strong>112 | 127 | 144 | 259 </strong> - PRZYSTANEK: RONDO ŚW. OJCA PIO</Paragraph2>
    <Paragraph2><strong>113</strong> - PRZYSTANEK: PARTYNICE TOR WYŚCIGÓW KONNYCH</Paragraph2><br/>
    <IconWrapper><Icon src={rower}/><Header>rower miejski</Header></IconWrapper>
    <Paragraph2><strong>STACJA ROWEROWA:</strong> AL. KARKONOSKA | ZWYCIĘSKA</Paragraph2><br/>
    <IconWrapper><Icon src={deska}/><Header>deskorolka i rolki</Header></IconWrapper>
    <Paragraph2>Mogą Ci się przydać podczas Festiwalu!</Paragraph2>
    <Paragraph2><strong>SPRAWDŹ DOSTĘPNE ATRAKCJE!</strong></Paragraph2><br/>
    <IconWrapper><Icon src={auto}/><Header>samochód</Header></IconWrapper>
    <Paragraph2><strong>DUŻY PARKING SAMOCHODOWY OD UL. ZWYCIĘSKIEJ ORAZ OD STRONY WYSOKIEJ.</strong></Paragraph2>
    <Paragraph2>NIE ZALECAMY KIEROWANIA POJAZDEM W TRAKCIE TRWANIA FESTIWALU %)</Paragraph2>
    <IconWrapper><Icon src={pkp}/><Header>pkp</Header></IconWrapper>
    <Paragraph2><strong>ZOSTAW AUTO I PRZYJEDŹ POCIĄGIEM Z CENTRUM MIASTA, Z SOBÓTKI, ZE ŚWIDNICY.</strong></Paragraph2>
    <Paragraph2>STACJA WROCŁAW PARTYNICE NA UL. ZWYCIĘSKIEJ</Paragraph2>
        </Wrapper>
        <Countdown/>
        <Map src={map}/>
        <Footer/>
        </>
    )
}

export default Directions;