import styled from "styled-components";
// import Heading from "../Heading/Heading";
import { organizatorzy, partnerzy, patroni, strategiczny } from "../../data";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import Exhibitor from "../Exhibitors/Exhibitor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 870px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
    padding-bottom: 100px;
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 50px;
  justify-content: center;
`;

const Heading = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #131339;
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 50px;
`;

const Announcement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #131339;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: -25px;
  margin-bottom: 25px;
`;

const NewsShort = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <Heading>Organizatorzy</Heading>
        <LogosWrapper>
          {organizatorzy.map((el) => (
            <Exhibitor img={el.img} link={el.link} />
          ))}
        </LogosWrapper>
        <Heading>Partner Strategiczny</Heading>
        {/* <Announcement>TBA</Announcement> */}
        <LogosWrapper>
          {strategiczny.map((el) => (
            <Exhibitor img={el.img} link={el.link} />
          ))}
        </LogosWrapper>
        <Heading>Partnerzy</Heading>
        {/* <Announcement>TBA</Announcement> */}
        <LogosWrapper>
          {partnerzy.map((el) => (
            <Exhibitor img={el.img} link={el.link} />
            ))}
          </LogosWrapper>
        <Heading>Patroni medialni</Heading>
        {/* <Announcement>TBA</Announcement> */}
        <LogosWrapper>
          {patroni.map((el) => (
            <Exhibitor img={el.img} link={el.link} />
          ))}
        </LogosWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

export default NewsShort;
