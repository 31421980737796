import styled from "styled-components";
import Countdown from "../Countdown/Countdown";
import Footer from "../Footer/Footer";
import HeroSection from "../HeroSection/HeroSection";
import InfoIcons from "../InfoIcons/InfoIcons";
import Navigation from "../Navigation/Navigation";
import NewsShort from "../NewsShort/NewsShort";
import Partners from "../Partners/Partners";
import Video from "../Video/Video";
import afterMovie from "../../images/aftermovie_2024.mov";
import Heading from "../Heading/Heading";

const Wrapper = styled.div`
  position: relative;
`;

const VideoWrapper = styled.div`
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
  /* @media (min-width: 1075px) {
    height: 42vw;
  } */
`;

const Button = styled.a`
  text-decoration: none;
  color: #e94b49;
  border: 4px solid #e94b49;
  padding: 20px 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  transition: 0.3s all ease;
  align-self: end;

  &:hover {
    border: 4px solid #e94b49;
    background-color: #e94b49;
    color: white;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0px 30px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: 1400px) {
    width: 70vw;
  }
`;

const MainPage = () => (
  <Wrapper>
    <Navigation />
    <HeroSection />
    <InfoIcons />
    <Countdown />
    <NewsShort />
    <ButtonWrapper>
      <Button href="/aktualnosci" target="_blank">
        ZOBACZ WIĘCEJ AKTUALNOŚCI
      </Button>
    </ButtonWrapper>
    <VideoWrapper>
      <Heading>Aftermovie 2024</Heading>
      <Video
        loop
        muted={true}
        autoPlay={true}
        playsInline={true}
        controls={true}
        width="100vw"
        height="auto"
      >
        <source src={afterMovie} type="video/mp4" />
      </Video>
    </VideoWrapper>
    {/* <Partners/> */}
    <Footer />
  </Wrapper>
);

export default MainPage;
