import styled from "styled-components";
import Footer from "../Footer/Footer";
import Heading from "../Heading/Heading";
import Navigation from "../Navigation/Navigation";

const Wrapper = styled.div`
  position: relative;
`;

const ThreeColumnGrid = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
`;

const HeadingWrapper = styled.div`
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 33.333%;
  padding: 0 40px;
  height: 100%;
  border-left: 4px dotted #131339;
  &:first-child {
    border: 0;
  }
  @media screen and (max-width: 1000px) {
    flex-basis: 100%;
    border: 0;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1400px) {
    &:first-child {
      border-left: 4px dotted #131339;
    }
    &:last-child {
      border-right: 4px dotted #131339;
    }
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 25px;
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
`;

const DayName = styled.p`
  background-color: #5ab1f7;
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  padding: 20px 20px;
  margin-bottom: 30px;
`;

const HighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  width: 100%;
`;

const HighlightName = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;

const HighlightPrimaryName = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  border: 3px solid black;
  padding: 10px 20px;
`;
const HyperLink = styled.a`
  outline: 0;
  text-decoration: none;
  border: 0;
  color: #5ab1f7;
  &:hover {
    opacity: 0.5;
  }
`;

const HighlightTime = styled.p`
  color: #5ab1f7;
  font-size: 20px;
  font-weight: 800;
`;

const Light = styled.span`
  font-weight: 500;
  vertical-align: 1px;
`;

const Highlight = (props) => {
  return (
    <HighlightWrapper>
      {props.primary ? (
        <HighlightPrimaryName>{props.name}</HighlightPrimaryName>
      ) : (
        <HighlightName>{props.name}</HighlightName>
      )}
      {props.time ? <HighlightTime>{props.time}</HighlightTime> : null}
    </HighlightWrapper>
  );
};

const Announcement = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  border: 3px solid black;
  padding: 10px 20px;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const LineUp = () => (
  <Wrapper>
    <Navigation />
    <HeadingWrapper>
      <Heading>na festiwalu</Heading>
    </HeadingWrapper>
    <Row>
      <HyperLink href="/aktualnosci/strefa-gastro-2024">
        <HighlightPrimaryName>
          60 Foodtrucków (strefa gastro)
        </HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/strefa-pasibusa-2024">
        <HighlightPrimaryName>STREFA PASIBUSA</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/rumbar-texico-2024">
        <HighlightPrimaryName>BARY by RUMBAR&TEXICO</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/coca-cola-2024">
        <HighlightPrimaryName>COCA-COLA CHALLENGE</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/strefa-omg-2024">
        <HighlightPrimaryName>STREFA GIER OMG</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/co-dla-dzieci-2024">
        <HighlightPrimaryName>STREFA KIDS </HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/zrob-sobie-dziare-na-festiwalu-2024">
        <HighlightPrimaryName>BARBER & TATTOO </HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/strefa-rekodziela-2024">
        <HighlightPrimaryName>STREFA RĘKODZIEŁA</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/regionalne-wina-sery-2024">
        <HighlightPrimaryName>REGIONALNE WINA I SERY</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/familijne-2024">
        <HighlightPrimaryName>GRA TERENOWA by FAMILIJNE</HighlightPrimaryName>
      </HyperLink>
      <HyperLink href="/aktualnosci/survival-race-kids-2024">
        <HighlightPrimaryName>SURVIVAL RACE KIDS</HighlightPrimaryName>
      </HyperLink>

      {/* <HighlightPrimaryName>WINIARZE I PRZYJACIELE</HighlightPrimaryName> */}
    </Row>
    <HeadingWrapper>
      <Heading>harmonogram</Heading>
      {/* <Heading>Szczegóły harmonogramu już wkrótce!</Heading> */}
    </HeadingWrapper>
    <ThreeColumnGrid>
      <Column>
        <DayName>
          PIĄTEK <Light>|</Light> 28.06
        </DayName>
        <Highlight name="start 17:00" primary />
        <Highlight name="Oficjalne rozpoczęcie Festiwalu" time="17:00" />
        <Highlight name="Panel dyskusyjny" time="17:30" />
        <Highlight name="DJ SET" time="18:00" />
        <HyperLink href="/aktualnosci/darmowe-koncerty-2024">
          <Highlight name="MUZYKA NA ŻYWO" primary />
        </HyperLink>
        <Highlight name="TEDE" time="20:30" />
        <HyperLink href="/aktualnosci/bitwa-o-wroclaw-2024">
          <Highlight
            name="Bitwa o Wrocław - freestyle battle vol2 by DZIK"
            time="22:00"
          />
        </HyperLink>
        <HyperLink href="/aktualnosci/silent-disco-2024">
          <Highlight name="Silent Disco by GRZEŚKI" time="23:30" />
        </HyperLink>
      </Column>

      <Column>
        <DayName>
          SOBOTA <Light>|</Light> 29.06
        </DayName>
        {/* <Announcement>TBA</Announcement> */}
        <Highlight name="start 10:00" primary />
        <HyperLink href="/aktualnosci/survival-race-kids-2024">
          <Highlight name="Survival Race Kids" time="12:00" />
        </HyperLink>
        <HyperLink href="/aktualnosci/kino-familijne-2024">
          <Highlight name="KINO FAMILIJNE by&nbsp;FAMILIJNE" primary />
        </HyperLink>
        <Highlight name='Emisja filmu - "Krudowie"' time="10:30 - 12:20" />
        <Highlight name="DJ SET" time="12:30 - 15:00" />
        <Highlight name="Finał Konkursu Best Food Truck" time="14:45" />

        <HyperLink href="/aktualnosci/pokazy-magii-2024">
          <Highlight
            name="POKAZY MAGICZNE"
            primary
          />
        </HyperLink>
        <Highlight name="Daniel Jedynak" time="15:00 - 16:00" />
        <Highlight name="Damian Kość" time="16:00 - 17:00" />
        <Highlight name="Czarek Czaruje" time="17:00 - 18:00" />

        <HyperLink href="/aktualnosci/darmowe-koncerty-2024">
          <Highlight name="MUZYKA NA ŻYWO" primary />
        </HyperLink>
        <Highlight name="BETHEL" time="19:30" />
        <Highlight name="GRUBSON" time="21:30" />
        <Highlight name="DJ SET" time="23:00" />
        <HyperLink href="/aktualnosci/silent-disco-2024">
          <Highlight name="Silent Disco by GRZEŚKI" time="23:30" />
        </HyperLink>
      </Column>

      <Column>
        <DayName>
          NIEDZIELA <Light>|</Light> 30.06
        </DayName>

        {/* <Announcement>TBA</Announcement> */}
        <Highlight name="start 10:00" primary />
        <HyperLink href="/aktualnosci/survival-race-kids-2024">
          <Highlight name="Survival Race Kids" time="10:00" />
        </HyperLink>
        <HyperLink href="/aktualnosci/kino-familijne-2024">
          <Highlight name="KINO FAMILIJNE by&nbsp;FAMILIJNE" primary />
        </HyperLink>
        <Highlight name='Emisja filmu - "Jak wytresować smoka"' time="10:30 - 12:00" />
        <Highlight name="DJ SET" time="12:00 - 14:00" />

        <Highlight name="BLOK KULINARNY" primary />
        <Highlight
          name="Dziki Bill - Konkurs jedzenia ostrych papryczek AMATORZY"
          time="14:00"
        />
        <Highlight
          name="Dziki Bill - konkurs jedzenia ostrych papryczek PRO"
          time="15:00"
        />
        <Highlight name="IKSS x HIGH OCTANE" time="16:00" />
        <HyperLink href="/aktualnosci/darmowe-koncerty-2024">
          <Highlight name="MUZYKA NA ŻYWO" primary />
        </HyperLink>
        <Highlight name="Klaudia Sobotka" time="17:30" />
        <Highlight name="MESAJAH" time="19:00" />
        <Highlight name="Ewelina Lisowska" time="20:30" />
        <HyperLink href="/aktualnosci/wakacje-dla-dzieci-z-domow-dziecka-2024">
          <Highlight name="FINAŁ LOTERII CHARYTATYWNEJ"  time="21:30"  />
        </HyperLink>
        <HyperLink href="/aktualnosci/stand-up-night-2024">
          <Highlight name="NOC&nbsp;ZE&nbsp;STANDUPEM" primary />
        </HyperLink>
        <Highlight name="" time="22:00 - 0:00" />
        <Highlight name="Marcel Bieńkowski" time="" />
        <Highlight name="Kutek" time="" />
        <Highlight name="Zalewski" time="" />
        <Highlight name="Łukasz Kowalski" time="" />
      </Column>
    </ThreeColumnGrid>
    <Footer />
  </Wrapper>
);

export default LineUp;
