import styled from "styled-components";
import Heading from "../Heading/Heading";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import loud_logo from "../../images/lp_logo.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 870px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
`;
const Image = styled.img`
  width: 100%;
`;

const Map = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <Heading>Mapa Festiwalu</Heading>

        <Image src="https://pasibus.blob.core.windows.net/media/6992/festiwal-mapa-www.png?width=500&mode=max&animationprocessmode=first" />
      </Wrapper>
      <Footer />
    </>
  );
};

export default Map;
