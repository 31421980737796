import styled from "styled-components";
import Heading from "../Heading/Heading";
import Exhibitor from "../Exhibitors/Exhibitor";
import { organizatorzy, partnerzy, patroni, strategiczny } from "../../data";

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 870px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
    padding-bottom: 100px;
  }
`;

const LogosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 50px;
`;

const NewsShort = () => {
  return (
    <Wrapper>
      <Heading>Organizatorzy</Heading>
      <LogosWrapper>
        {organizatorzy.map((el) => (
          <Exhibitor img={el.img} link={el.link} />
        ))}
      </LogosWrapper>
      <Heading>Partner Strategiczny</Heading>
      <LogosWrapper>
        {strategiczny.map((el) => (
          <Exhibitor img={el.img} link={el.link} />
        ))}
      </LogosWrapper>
      <Heading>Partnerzy</Heading>
      <LogosWrapper>
        {partnerzy.map((el) => (
          <Exhibitor img={el.img} link={el.link} />
        ))}
      </LogosWrapper>
      <Heading>Patroni medialni</Heading>
      <LogosWrapper>
        {patroni.map((el) => (
          <Exhibitor img={el.img} link={el.link} />
        ))}
      </LogosWrapper>
    </Wrapper>
  );
};

export default NewsShort;
