import styled from "styled-components";
import Heading from "../Heading/Heading";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import MemberRegulation from "../../regulations/regulamin-uczestnika-festiwalu-pasibrzucha-25.06.2024.pdf";
import StaffRegulation from "../../regulations/Regulamin Wystawców Festiwal Pasibrzucha 24.04.2024.pdf";
import MemberPayEyeRegulation from "../../regulations/festiwal-pasibrzucha-2022_regulamin-dla-uzytkownikow_pay_eye.pdf";
import StaffPayEyeRegulation from "../../regulations/festiwal-pasibrzucha-2022-regulamin-dla-wystawcy_pay_eye.pdf";
import CraftZoneRegulation from "../../regulations/regulamin-wystawcy-wroclaw-strefa-rekodziela-na-festiwalu-pasibrzucha-2023.pdf";
import FotoBudkaRegulation from "../../regulations/REGULAMIN_FESTIWALOWA_FOTO-BUDKA.pdf";
import BestFoodRegulation from "../../regulations/REGULAMIN_FESTIWALOWY_BEST_FOOD_CHALLENGE.pdf";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 870px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 10vw;
  }
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #131339;
  font-weight: 700;
  font-size: 20px;
  padding: 25px;
  &:hover {
    opacity: 0.4;
  }
  @media screen and (max-width: 870px) {
    font-size: 16px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
`;

const Regulations = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <Heading>regulaminy</Heading>
        <LinkWrapper>
          <Link href={MemberRegulation}>Regulamin Uczestnika Festiwalu</Link>
          <Link href={StaffRegulation}>Regulamin Wystawcy Festiwalu</Link>
          {/* <Link href={StaffPayEyeRegulation}>
            Regulamin PAYEYE Wystawcy Festiwalu
          </Link> */}
          {/* <Link href={CraftZoneRegulation}>
            Regulamin Wystawcy Festiwalu - Craft Zone
          </Link> */}
          {/* <Link href={CraftZoneRegulation}>
            Regulamin Wystawcy Festiwalu - Foto Budka
          </Link>
          <Link href={CraftZoneRegulation}>
            Regulamin Wystawcy Festiwalu - Best Food
          </Link> */}
        </LinkWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Regulations;
