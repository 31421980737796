import styled from "styled-components";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import Heading from "../Heading/Heading";
import NewsShort from "../NewsShort/NewsShort";

const Wrapper = styled.div`
position:relative;
`

const Description = styled.p`
margin:50px;
text-align:justify ;
`

const Masonry = styled.div`
padding:0 50px;
  column-count: 4;
  column-gap: 0.6em;
  @media (max-width: 1600px) {
    column-count: 3;
  }
  @media (max-width: 1000px) {
    column-count: 2;
  }
`;

const Image = styled.img`
  display: inline-block;
  margin:0.21em 0;
  width: 100%;
`;

const GalleryFriday = ()=>{
  window.scrollTo(0, 0);
    const Images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83]
    
    return (
        <Wrapper>
            <Navigation/>
            <Heading>Tak rozpoczęliśmy drugą edycję Festiwalu Pasibrzucha.</Heading>
            <Description>
            Drugą edycję Festiwalu Pasibrzucha rozpoczęliśmy w piątkowe popołudnie! Na Wrocławskim Torze Wyścigów Konnych Partynice zgromadziliśmy dla Was prawie 50 różnych konceptów gastronomicznych, przygotowaliśmy strefy specjalne oraz atrakcje na scenie głównej. Wieczorne występy rozpoczęliśmy od występów Stand-Up podczas których o uśmiechy na Waszych twarzach zadbali Konrad Lis, Michał Juszczak, Alex Niebrzegowski.
<br/><br/>
Następnie scenę przejął O.S.T.R., znany polski raper, którego nikomu na Festiwalu nie trzeba było przedstawiać, co było widać po tłumach pod sceną. Przerosło to nasze najśmielsze oczekiwania i rozpoczęło 3 dniowy Festiwal z ogromnym przytupem! Na tym nie skończyliśmy piątkowego wieczoru. Dla tych najbardziej wytrwałych przygotowaliśmy darmowe kino nocne, na ekranie pojawił się kultowy film Pulp Fiction. A ci z Was, którzy byli spragnieni zabawy zostali z nami na specjalnej strefie imprezowej Buha. Dochodziły nas słuchy, że bawiliście się do rana ;)
            </Description>
        <Masonry>
            {
                Images.map(el=>{
                    return(
                        <Image src={`/galeria_piatek/festiwal-piatek-${el}.jpg`}/>
                    );
                })
            }
            

        </Masonry>
        <NewsShort/>
        <Footer/>
        </Wrapper>
        
    );
}

export default GalleryFriday;