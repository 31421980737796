import styled from "styled-components"
import { Link } from "react-router-dom"


const Wrapper = styled.div`
display:flex;
position:relative;
flex-direction:column;
align-items:flex-start;
flex-basis:100%;
padding-bottom:25px;

@media screen and (min-width:870px){
    flex-basis:50%;
    padding:10px;
    padding-bottom:25px;
}
@media screen and (min-width:1400px){
    flex-basis:25%;
}
`
const Image = styled.img`
width:100%;
margin-bottom:10px;
border:1px solid gray;
`

const Title = styled.p`
color:#131339;
font-weight:700;
font-size:22px;
margin-top:10px;
@media screen and (min-width:870px){
    font-size:18px;
}
@media screen and (min-width:1400px){
    font-size:18px;
}
`
const ReadMore = styled.p`
display:flex;
color:#131339;
font-weight:400;
font-size:22px;
margin-top:10px;
text-decoration: underline;
@media screen and (min-width:870px){
    font-size:18px;
}
@media screen and (min-width:1400px){
    font-size:14px;
}
`

const GalleryFridayThumbnail = (props) => {
    return (
        <Wrapper>
            <Link to="/aktualnosci/galeria-sobota" style={{alignSelf:"flex-end"}}><Image src="https://pasibus.blob.core.windows.net/media/3996/fotorelacja-piatek-1.png?width=500&mode=max&animationprocessmode=first"/></Link>
            <Title>Drugi dzień Festiwalu Pasibrzucha - dla każdego coś dobrego.</Title>
            <Link to="/aktualnosci/galeria-sobota" style={{alignSelf:"flex-end"}}><ReadMore>Czytaj więcej...</ReadMore></Link>
        </Wrapper>

    )
}

export default GalleryFridayThumbnail;